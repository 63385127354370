import './App.css';
import ComponentEditor from './componentEditor';

function App() {
  return (
    <main className="">
      <ComponentEditor />
      </main>
  );
}

export default App;
