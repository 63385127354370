import React, { useState, useEffect } from "react";

function camelCaseToSpaceSeparated(string) {
    return string.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, function (str) { return str.toUpperCase(); });
}

async function getBrands() {
    const response = await fetch('https://tokens.so5.dev/brands.json');
    const data = await response.json();
    const brands = Object.keys(data).map((brand) => {
        return {
            value: brand,
            label: data[brand].displayName
        }
    });
    return brands;
}

function ComponentEditor() {
    const [tokens, setTokens] = useState({});
    const [componentTypes, setComponentTypes] = useState([]);
    const [properties] = useState(["paddingTop", "paddingBottom", "paddingLeft", "paddingRight", "fontFamily", "fontWeight", "cornerRadius"]);
    const [styles] = useState(["block", "outline", "ghost"]);
    const [sizes] = useState(["small", "medium", "large","default"]);
    const [metaData] = useState(["sizes", "styles", "colors"]);
    const [palettes, setPalettes] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState("demo-brand");
    const [activeSection, setActiveSection] = useState("Component Styles");



    useEffect(() => {
        fetch(`https://tokens.so5.dev/brands/${selectedBrand}/variables.json`)
            .then(response => response.json())
            .then(data => {
                setTokens(data);
                setPalettes(Object.keys(data.color.theme));
                setComponentTypes(Object.keys(data).filter(key => data[key].metaData?.type === "component"));
            });
    }, [selectedBrand]);

    const [brand, setBrand] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const path = window.location.pathname.split('/');
        setBrand(path[1] || 'default');

        // call getBrands function and update options state
        getBrands().then(brands => setOptions(brands));
    }, []);



    const handlePublish = () => {
        alert('TODO');
        console.log(brand);
    };

    return (
        <div>
            
            <div className="flex flex-col h-screen">
                <header className="text-left ca-bg-neutral-800 p-6 flex justify-between">
                <h1 className="ca-text-neutral-dark-contrast font-bold text-lg">{tokens.brand?.displayName}</h1>
                <select value={selectedBrand} onChange={e => setSelectedBrand(e.target.value)} className="ca-select ca-color-neutral-light ca-style-outline+interactive">
                {
                    options.map(option => (
                        <option key={option.value} value={option.value}>{option.label}</option>
                    ))
                }
            </select>
            </header>
            <nav className="ca-bg-neutral-50 p-4 flex flex-row justify-between pt-8">
  <div className="flex gap-4">
    <button className={`ca-btn--large ca-color-neutral-dark ${activeSection === "Component Styles" ? " ca-style-block+interactive" : " ca-style-outline+interactive"}`} onClick={() => setActiveSection("Component Styles")}>
      Component Styles
    </button>
    <button className={`ca-btn--large ca-color-neutral-dark ${activeSection === "Colour Palettes" ? " ca-style-block+interactive" : " ca-style-outline+interactive"}`} onClick={() => setActiveSection("Colour Palettes")}>
      Colour Palettes
    </button>
    <button className={`ca-btn--large ca-color-neutral-dark ${activeSection === "Typography" ? " ca-style-block+interactive" : " ca-style-outline+interactive"}`} onClick={() => setActiveSection("Typography")}>
      Typography
    </button>
  </div>
  <button className="ca-btn--large ca-color-success ca-style-block+interactive w-60 " onClick={handlePublish}>
    Publish
  </button>
</nav>

            <main className="flex-1 ca-bg-neutral-50 px-4">
                <div className="bg-white p-4 mb-4 rounded-lg">
                    {activeSection === "Component Styles" && <div>
                        <h2 className="ca-h3 mb-2">Components</h2>
                        {componentTypes.map(componentType => {
                            if (tokens[componentType]) {
                                const component = tokens[componentType];
                                return (
                                    <div key={componentType}>
                                        <details className="mb-4">
                                            <summary className="ca-summary ca-color-neutral-light ca-style-block+interactive"><strong>{tokens[componentType].metaData.displayName}</strong></summary>
                                            <form>
                                                <div className="flex justify-between">
                                                    <div>
                                                        {properties.map(property => {
                                                            if (component[property]) {
                                                                let value = component[property];
                                                                if (typeof value === "object" && value.hasOwnProperty("value")) {
                                                                    value = value.value;
                                                                }
                                                                return (
                                                                    <div key={property} className="my-4">
                                                                        <label className="w-48 inline-block">{camelCaseToSpaceSeparated(property)}</label>
                                                                        <input className="ca-text ca-color-neutral-dark ca-style-form-default+interactive" type="text" defaultValue={value} name={property} />
                                                                    </div>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                        {properties.filter(property => !component[property]).length > 0 &&
                                                            <div>
                                                                <label>Add New</label>
                                                                <select>
                                                                    {properties.map(property => {
                                                                        if (!component[property]) {
                                                                            return <option key={property} value={property}>{property}</option>;
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })}
                                                                </select>
                                                            </div>
                                                        }
                                                    </div><div>
                                                        <article className="rounded-sm shadow-sm border p-4 w-96 my-4">
                                                            <header className="ca-h4">Component options</header>
                                                            {metaData.map(meta => {
                                                                let options;
                                                                switch (meta) {
                                                                    case "styles":
                                                                        options = styles;
                                                                        break;
                                                                    case "sizes":
                                                                        options = sizes;
                                                                        break;
                                                                    case "colors":
                                                                        options = palettes;
                                                                        break;
                                                                    default:
                                                                        return null;
                                                                }
                                                                return (
                                                                    <div key={meta}>
                                                                        <h6 className="ca-h6 mt-4 mb-2">{meta}</h6>
                                                                        {options.map(item => {
                                                                            return (
                                                                                <div key={meta + item}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={item}
                                                                                        name={item}
                                                                                        value={item}
                                                                                        defaultChecked={component.metaData[meta] && component.metaData[meta].includes(item)}
                                                                                        className="ca-checkbox ca-color-neutral-light ca-style-block+interactive"
                                                                                    />
                                                                                    <label htmlFor={item} className="ml-2">{item}</label>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                );
                                                            })}
                                                        </article>

                                                    </div></div></form></details>
                                        {/* <button onClick={() => handleSave(component)}>Save</button> */}

                                    </div>

                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>}
                    {activeSection === "Colour Palettes" && <div>
                        <h2 className="ca-h3 mb-2">Colour Palettes</h2>
                        <div className="flex flex-wrap gap-4">
                        {
                            palettes.map(palette => {
                                return (
                                    <div className="w-60 border shadow-sm rounded-md p-4">
                                        <h4 className="mb-4 ca-h4">{palette}</h4>
                                        <div className="grid">
                                            {
                                                Object.keys(tokens.color.theme[palette]).map(color => {
                                                    return (
                                                        <div className="my-4">
                                                            <input type="color"  className="ca-text ca-color-neutral-dark ca-style-form-default+interactive p-0 w-8" defaultValue={tokens.color.theme[palette][color].value} />
                                                            <label className="ml-4 inline-block">{color}</label>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                            )}</div>
                    </div>}
                    {activeSection === "Typography" && <div>
                        <h2 className="ca-h3 mb-2">Typography</h2>
                        {/* Typography section content goes here */}
                    </div>}
                </div>
            </main>
            <footer className="bg-gray-800 p-4 text-white">
                <p>&copy; Superset</p>
            </footer>
            

            </div>
            </div>
    );
}

export default ComponentEditor;
